






































































































































































import { UserApi } from "@/networks/UserApi";
import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";
import store from "../../../store";

@Component
export default class App extends Vue {
  part1 = null;
  part2 = null;
  part3 = null;
  part4 = null;
  private credintials: any = {
    email: "",
    password: "",
    code: ""
  };
  private codeActiveField = 0;
  private type = "password";
  private errors: Record<string, Array<string>> = {};
  step = "phone";
  mounted() {
    if (localStorage.getItem("token")) {
      this.$router.replace({ name: "dashboard" });
    }
    document.title = (this.$route.meta as any).title || "GO2TR BMS";
  }

  public async login(): Promise<void> {
    try {
      const res = await new UserApi().loginUserByEmail(this.credintials);
      if (res.data.token) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        await this.$router.push({ name: "dashboard" });
      }
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message,
          color: "danger"
        });
      }
    }
  }
  onKeyup($event: any) {
    try {
      if ($event.code == "Backspace") {
        if ($event.target.previousElementSibling && !$event.target.value) {
          $event.target.previousElementSibling.focus();
        }
      } else {
        if ($event.target.nextElementSibling) {
          $event.target.nextElementSibling.focus();
        }
      }
    } catch (error: any) {
      console.error(error);
    }
  }
  public async loginByCode(): Promise<void> {
    try {
      let code: any = {
        email: this.credintials.email,
        code: `${this.part1}${this.part2}${this.part3}${this.part4}`
      };
      const res = await new UserApi().loginByCode(code);
      if (res.data.token) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        await this.$router.push({ name: "dashboard" });
      }
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message,
          color: "danger"
        });
      }
    }
  }

  public async sendCode(): Promise<void> {
    try {
      this.step = "code";
      const res = await new UserApi().check({
        mobile: this.credintials.email
      });
      this.codeActiveField = 1;
    } catch (error: any) {
      if (error.response.status == 493) {
        this.codeActiveField = 1;
      }
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message,
          color: "danger"
        });
      }
    }
  }

  @Watch("credintials.code")
  public watchCode() {
    if (Number.isNaN(Number(this.credintials.code))) {
      this.errors.code = ["کد وارد شده باید عدد باشد"];
    } else {
      this.errors.code = [];
    }
  }
}
