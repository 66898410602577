import Api from "./api";
import { AxiosError, AxiosResponse } from "axios";
import internal from "stream";

export class UserApi extends Api {
  private token = "";

  constructor() {
    super();
    this.token = localStorage.getItem("token") || "";
  }

  private getFormData = (object: Record<string, any>) =>
    Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());

  public check(credentials: loginCheck): Promise<ServerResponse> {
    return this.post<ServerResponse>("/check", credentials)
      .then((response: AxiosResponse<ServerResponse>) => {
        const { data } = response;
        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public loginUser(credentials: login): Promise<ServerResponse> {
    return this.post<ServerResponse>("/login", credentials)
      .then((response: AxiosResponse<ServerResponse>) => {
        const { data } = response;

        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public loginUserByEmail(credentials: loginEmail): Promise<ServerResponse> {
    return this.post<ServerResponse>("/login-email", credentials)
      .then((response: AxiosResponse<ServerResponse>) => {
        const { data } = response;

        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public loginByCode(credentials: loginEmail): Promise<ServerResponse> {
    return this.post<ServerResponse>("/login", credentials)
      .then((response: AxiosResponse<ServerResponse>) => {
        const { data } = response;

        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public countryContract(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("/country-contract", data)
      .then((response: AxiosResponse) => {
        const { data } = response;

        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public confirmContract(id: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/confirm-contract/" + id)
      .then((response: AxiosResponse) => {
        const { data } = response;

        return data;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  public getEvent(
    id: string,
    check: string,
    post = false,
    coupon?: string,
    inviteNumber?: string
  ): Promise<AxiosResponse> {
    if (post)
      return this.post<ServerResponse>(
        `/pay/${id}/${check}?coupon=${coupon}&invite=${inviteNumber}`,
        {}
      );
    else return this.get<ServerResponse>(`/pay/${id}/${check}`);
  }

  public getPayInfo(trx: string, user_id: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>(`/p/${trx}/${user_id}`, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public submitAndGetUrl(trx: string, user_id: string, coupon = ""): Promise<AxiosResponse> {
    return this.post<ServerResponse>(`/p/${trx}/${user_id}?coupon=${coupon}`, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public checkAvailability(
    params: Record<string, string>
  ): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/users/check-availability", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params
    });
  }

  public searchUser(params: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/users/search", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params
    });
  }

  public quick(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("/users/quick", data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public store(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("users", this.getFormData(data), {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public edit(id: number | string, data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("users/" + id, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public list(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("users", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }

  public deleteUser(id: string): Promise<AxiosResponse> {
    return this.delete<ServerResponse>("users/" + id, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public getUser(id: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>("users/" + id, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public resume(id: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>(`users/${id}/resume`, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public getMentorPublic(id: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>("mentor/" + id);
  }

  public getStaff(): Promise<AxiosResponse> {
    return this.get<ServerResponse>("staff/search", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public getUserResume(id: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>(`users/${id}/resume`, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public getUserFileDetail(id: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>(`users/${id}/file`, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public getUserTransactionsList(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>(`transaction`, {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }

  public getUserSensitiveInfo(user_id: number, field: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>(`users/sensitive-info?user_id=${user_id}&field=${field}`, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }
}
